$form-stepper-width: 481px;

@import 'styles/layers.scss';

@layer component {
  .wrap {
    height: 100%;
  }
  
  .body {
    padding: 0;
    width: $form-stepper-width;
    height: 100%;
  }
  
  .content {
    width: $form-stepper-width;
    border: none;
    max-height: 520px;
    height: calc(100% - var(--bs-modal-margin) * 2);
  }

  .form-stepper {
    width: 100%;
  }
}