@import 'styles/layers.scss';

@layer component {
  .modalContent {
    height: 100%;
  }
  
  .modalDialog {
    min-width: 525px;
  }
  
  .modalBody {
    height: 100%;
    padding: 0;
  }
}
